/**
 * Класс ShopaholicCartRemove управляет удалением товаров из корзины.
 *
 * Основные функции:
 *
 * - Инициализация обработчиков событий для кнопок удаления.
 * - Обработка кликов на кнопки удаления и отправка AJAX-запросов для удаления товара.
 * - Обновление данных корзины после удаления товара.
 */

import ShopaholicCartPosition from "./shopaholic-cart-position";
import ShopaholicCart from "./shopaholic-cart";
import ShopaholicCartShippingType from "./shopaholic-cart-shipping-type";


export default class ShopaholicCartRemove {
  constructor() {
    this.sDefaultButtonClass = '_shopaholic-cart-remove';
    this.sRemoveComponentMethod = 'Cart::onRemove';
    this.obAjaxRequestCallback = null;
    ShopaholicCart.instance();

  }

  /**
  * Init event handlers
  */
  init() {
      document.addEventListener('click', (event) => {

          const obButton = event.target.closest(`.${this.sDefaultButtonClass}`);

          if (obButton && obButton.classList.contains(this.sDefaultButtonClass)) {
              event.preventDefault();

              if (obButton.hasAttribute('disabled')) {
                  return;
              }
              this.sendAjaxRequest(obButton);
          }
      });
  }


  /**
   * Remove cart position
   * @param {node} obButton
   */
  sendAjaxRequest(obButton) {
    if (!obButton) {
      throw new Error('Button node is empty.');
    }

    obButton.setAttribute('disabled', 'disabled');
    const obCartPosition = new ShopaholicCartPosition(obButton);
    const iPositionID = obCartPosition.getPositionID();
    const obShippingType = new ShopaholicCartShippingType();


    let obRequestData = {
      data: {
        cart: [iPositionID],
        type: 'position',
        'shipping_type_id': obShippingType.getShippingTypeID()
      },
      complete: (response) => {

        this.completeCallback(response.data);
        location.reload()
      },
    };

    if (this.obAjaxRequestCallback !== null) {
      obRequestData = this.obAjaxRequestCallback(obRequestData, obButton);
    }

       oc.ajax(this.sRemoveComponentMethod, obRequestData);
      // oc.ajax(this.sRemoveComponentMethod, {
      //     data: obRequestData,
      //     'update': {'cart/cartPositionList': '#cartPositionItem'},
      //     success: () => {
      //         oc.flashMsg({
      //             message: 'Товар удален из корзины',
      //             type: 'success',
      //             interval: 1
      //         });
      //
      //     },
      //     error: () => {
      //         oc.flashMsg({
      //             message: 'Товар не добавлен в корзину, свяжитесь с администратором сайта',
      //             type: 'error',
      //             interval: 1
      //         });
      //     },
      //     complete: (data) => {
      //         this.updateCartInfo(data);
      //     }
      // });
  }
    updateCartInfo(data) {
        const cartCounter = document.querySelector('.cart-info__counter');
        if (cartCounter && data.data.total_quantity != null) {
            cartCounter.innerHTML = data.data.total_quantity;
        }
    }
  /**
   * Remove disabled attribute from button
   * Update cart data in ShopaholicCart object
   */
  completeCallback(obResponse, obButton) {
    const obCartData = obResponse;

    ShopaholicCart.instance().updateCartData(obCartData);

    if (obButton) {
      obButton.removeAttribute('disabled');
    }
  }

  /**
   * Set ajax request callback
   *
   * @param {function} obCallback
   * @returns {ShopaholicCartRemove}
   */
  setAjaxRequestCallback(obCallback) {
    this.obAjaxRequestCallback = obCallback;

    return this;
  }
}
