/**
 * Класс ShopaholicCartUpdate управляет обновлением количества товаров в корзине.
 *
 * Основные функции:
 *
 * - Инициализация обработчиков событий для обновления, увеличения и уменьшения количества товаров.
 * - Обработка изменений количества товаров и отправка AJAX-запросов для обновления данных корзины.
 * - Обновление данных корзины после изменения количества товаров.
 */

import ShopaholicCartPosition from "./shopaholic-cart-position";
import ShopaholicCart from "./shopaholic-cart";
import ShopaholicCartShippingType from "./shopaholic-cart-shipping-type";


export default class ShopaholicCartUpdate {
    constructor() {
        this.sDefaultInputClass = '_shopaholic-cart-quantity';
        this.sIncreaseInputClass = '_shopaholic-cart-increase-quantity';
        this.sDecreaseInputClass = '_shopaholic-cart-decrease-quantity';
        this.sUpdateComponentMethod = 'Cart::onUpdate';
        this.obAjaxRequestCallback = null;

        this.iDelayBeforeRequest = 400;
        this.obTimer = null;

        ShopaholicCart.instance();
    }

    /**
     * Init event handlers
     */
    init() {
        this.initUpdateEvent();
        // this.initIncreaseEvent();
        // this.initDecreaseEvent();

    }

    /**
     * Init update event
     */
    initUpdateEvent() {

        document.addEventListener('input', (event) => { console.log('initUpdateEvent');
            const obInput = event.target.closest(`.${this.sDefaultInputClass}`);
            if (obInput && obInput.classList.contains(this.sDefaultInputClass)) {
                if (this.obTimer !== null) {
                    clearTimeout(this.obTimer);
                }

                this.obTimer = setTimeout(() => {
                    const iMaxQuantity = this.getMaxQuantity(obInput);
                    let iQuantity = this.getQuantity(obInput);
                    if (iQuantity < 1) {
                        iQuantity = 1;
                    }

                    if (iQuantity > iMaxQuantity) {
                        obInput.value = iMaxQuantity;
                        return;
                    }
                    console.log('initUpdateEvent', iQuantity);
                    console.log('obInput.value', iMaxQuantity);
                    this.sendAjaxRequest(obInput);
                }, this.iDelayBeforeRequest);
            }
        });
    }


    /**
     * Init increase event
     */
    // initIncreaseEvent() {
    //     document.addEventListener('click', (event) => { console.log('initIncreaseEvent');
    //
    //         const obButton = event.target.closest(`.${this.sIncreaseInputClass}`);
    //         if (obButton) {
    //             if (this.obTimer !== null) {
    //                 clearTimeout(this.obTimer);
    //             }
    //
    //             const obCartPosition = new ShopaholicCartPosition(obButton);
    //             const obInput = obCartPosition.getQuantityInput();
    //             const iMaxQuantity = this.getMaxQuantity(obInput);
    //             let iQuantity = this.getQuantity(obInput) + 1;
    //             if (iQuantity > iMaxQuantity) {
    //                 return;
    //             }
    //
    //             obInput.value = iQuantity;
    //             if (iQuantity == iMaxQuantity) {
    //                 obButton.setAttribute('disabled', 'disabled');
    //             } else {
    //                 obButton.removeAttribute('disabled');
    //             }
    //
    //             if (iQuantity > 1) {
    //                 const obCartNode = obCartPosition.getNode();
    //                 const obDecreaseButton = obCartNode.querySelector(`.${this.sDecreaseInputClass}`);
    //                 if (obDecreaseButton) {
    //                     obDecreaseButton.removeAttribute('disabled');
    //                 }
    //             }
    //
    //             this.obTimer = setTimeout(() => {
    //                 this.sendAjaxRequest(obInput);
    //             }, this.iDelayBeforeRequest);
    //         }
    //     });
    // }
    //
    // initDecreaseEvent() {
    //     document.addEventListener('click', (event) => {
    //         const obButton = event.target.closest(`.${this.sDecreaseInputClass}`);
    //
    //         if (obButton) {
    //             if (this.obTimer !== null) {
    //                 clearTimeout(this.obTimer);
    //             }
    //
    //             const obCartPosition = new ShopaholicCartPosition(obButton);
    //             const obInput = obCartPosition.getQuantityInput();
    //             const iMaxQuantity = this.getMaxQuantity(obInput);
    //             let iQuantity = this.getQuantity(obInput) - 1;
    //             if (iQuantity < 1) {
    //                 return;
    //             }
    //
    //             obInput.value = iQuantity;
    //             if (iQuantity == 1) {
    //                 obButton.setAttribute('disabled', 'disabled');
    //             } else {
    //                 obButton.removeAttribute('disabled');
    //             }
    //
    //             if (iQuantity < iMaxQuantity) {
    //                 const obCartNode = obCartPosition.getNode();
    //                 const obIncreaseButton = obCartNode.querySelector(`.${this.sIncreaseInputClass}`);
    //                 if (obIncreaseButton) {
    //                     obIncreaseButton.removeAttribute('disabled');
    //                 }
    //             }
    //
    //             this.obTimer = setTimeout(() => {
    //                 this.sendAjaxRequest(obInput);
    //             }, this.iDelayBeforeRequest);
    //         }
    //     });
    // }

    /**
     * Update position data
     * @param {node} obInput
     */
    sendAjaxRequest(obInput, isShippingUpdate = false) {

        if (!obInput) {
            throw new Error('Input node is empty.');
        }

        let obRequestData;

        if (isShippingUpdate) {
            const obShippingType = new ShopaholicCartShippingType();
            obRequestData = {
                data: {
                    'shipping_type_id': obShippingType.getShippingTypeID(),
                    cart: this.getCartData() // Добавляем данные корзины
                },
                complete: (response) => {
                    this.completeCallback(response.data);
                },
            };
        } else {
            const obCartPosition = new ShopaholicCartPosition(obInput);
            let obPositionData = obCartPosition.getData();
            const obShippingType = new ShopaholicCartShippingType();

            obRequestData = {
                data: {
                    cart: [obPositionData],
                    'shipping_type_id': obShippingType.getShippingTypeID()
                },
                complete: (response) => {

                    this.completeCallback(response.data);
                    console.log(response.data);
                },
            };
        }

        if (this.obAjaxRequestCallback !== null) {
            obRequestData = this.obAjaxRequestCallback(obRequestData, obInput);
        }

        oc.ajax(this.sUpdateComponentMethod, obRequestData);
    }

// Добавляем метод для получения данных корзины
    getCartData() {
        const cartItems = [];
        document.querySelectorAll('.shopaholic-product-wrapper').forEach(wrapper => {
            const obCartPosition = new ShopaholicCartPosition(wrapper);
            cartItems.push(obCartPosition.getData());
        });
        return cartItems;
    }

    getQuantity(obInput) {
        return parseInt(obInput.value, this.iRadix);
    }

    /**
     * Get offer quantity from cart object
     */
    getMaxQuantity(obInput) {
        return parseInt(obInput.getAttribute('max'), this.iRadix);
    }

    /**
     * Remove disabled attribute from button
     * Update cart data in ShopaholicCart object
     */
    completeCallback(obResponse) {
        const obCartData = obResponse;
        ShopaholicCart.instance().updateCartData(obCartData);
    }

    /**
     * Set ajax request callback
     *
     * @param {function} obCallback
     * @returns {ShopaholicCartUpdate}
     */
    setAjaxRequestCallback(obCallback) {
        this.obAjaxRequestCallback = obCallback;

        return this;
    }
}
