/**
 * Этот файл включает функционал для работы с корзиной покупок и заказами в приложении Shopaholic.
 * Импортируются модули для обновления корзины, удаления из корзины и обработки заказов.
 */


import ShopaholicCartUpdate from './shopaholicTrash/shopaholic-cart/shopaholic-cart-update';
import ShopaholicCartRemove from './shopaholicTrash/shopaholic-cart/shopaholic-cart-remove';
import ShopaholicOrder from './shopaholicTrash/shopaholic-cart/shopaholic-order';


const obShopaholicCartUpdate = new ShopaholicCartUpdate();
const obShopaholicCartRemove = new ShopaholicCartRemove();

obShopaholicCartUpdate.init();
obShopaholicCartRemove.init();

// Обработчик нажатия на кнопку отправки заказа
function handleSendOrder(event) {
    const obButton = event.target.closest('#sendOrder');
    if (obButton) {
        const requiredInputs = document.querySelectorAll('input.need_validation');
        let allValid = true;

        requiredInputs.forEach(input => {
            if (!input.value.trim()) {
                allValid = false;
                oc.flashMsg({
                    message: `Поле ${input.placeholder || input.name} обязательно к заполнению.`,
                    type: 'error',
                    interval: 2
                });
            }
        });

        if (allValid) {
            const obShopaholicOrder = new ShopaholicOrder();
            obShopaholicOrder.setAjaxRequestCallback((obRequestData, obInput) => obRequestData);
            obShopaholicOrder.create();
            openModalThanks();
        }
    }
}

function openModalThanks() {
    let modalThanks = document.getElementById('modal-thanks');
    modalThanks.style.display = 'block';
    modalThanks.style.opacity = '1';
    modalThanks.classList.remove('invisible');
}



function closeModalThanks() {
    let modalThanks = document.getElementById('modal-thanks');
    modalThanks.style.display = 'none';
    modalThanks.style.opacity = '0';
    modalThanks.classList.add('invisible');
}

let closeButtonThanks = document.querySelector('.modal-thanks-close');
closeButtonThanks.addEventListener('click', closeModalThanks);

window.onclick = function (event) {
    let closeModalThanks = document.getElementById('modal-thanks');
    if (event.target == closeModalThanks) {
        closeModalThanks();
    }
}

// Обработчик обновления корзины
function handleCartUpdate() {
    obShopaholicCartUpdate.setAjaxRequestCallback((obRequestData, obInput) => {
        let resultCounter = obRequestData.data.cart.length;

            const cartCounter = document.querySelector('.cart-info__counter');
            if (cartCounter) {
                cartCounter.innerHTML = data.data.total_quantity;
            }

        return obRequestData;
    }).init();
}

// Обработчик удаления из корзины
function handleCartRemove(event) {
    const obButton = event.target.closest('.shopaholic-cart-remove');
    if (obButton) {
        const obShopaholicCartRemove = new ShopaholicCartRemove();
        obShopaholicCartRemove.setAjaxRequestCallback((obRequestData, obButton) => {
            const productWrapper = obButton.closest('.shopaholic-product-wrapper');
            if (productWrapper) {
                obRequestData.data.product_id = productWrapper.getAttribute('data-product-id');
            }
            obRequestData.update = {
                'cart/cartPositionList': '#cartPositionList',
            };
            obRequestData.complete = () => {
                oc.flashMsg({
                    message: 'Товар удален из корзины',
                    type: 'success',
                    interval: 1
                });
            };
            return obRequestData;
        }).init();
    }
}

// Обработчик очистки корзины
function handleClearCart() {
    const clearCartButton = document.getElementById('clearCartAll');
    if (clearCartButton) {
        clearCartButton.addEventListener('click', () => {
            oc.ajax('Cart::onClear', {
                update: {'cart/cartPositionList': '#cartPositionItem'},
                complete: () => location.reload(),
                error: (response) => console.error('Error clearing cart:', response)
            });
        });
    }
}

// Обработчик изменения цены доставки
function handleShippingPrice() {
    const shippingPriceElement = document.querySelector('._shopaholic-cart[data-group="shipping_price"][data-field="price"]');

    if (shippingPriceElement && parseFloat(shippingPriceElement.textContent) === 0) {
        shippingPriceElement.textContent = 'Бесплатно';
    }

    function updateShippingPrice() {
        console.log('shippingPriceElement:' + shippingPriceElement);
        const price = parseFloat(shippingPriceElement.textContent);
        console.log('Shipping price:' + price);
        if (price === 0) {
            shippingPriceElement.textContent = 'Бесплатно';
        }
    }

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList' || mutation.type === 'characterData') {
                updateShippingPrice();
            }
        });
    });

    if (shippingPriceElement) {
        observer.observe(shippingPriceElement, {childList: true, characterData: true, subtree: true});
    }
}

// Обработчик переключения способов доставки
// function handleToggleAddressBlock() {
//     const deliveryOptions = document.querySelectorAll('input[name="shipping_type_id"]');
//     const addressBlock = document.querySelector('._js_address_block');
//     // const inputsInAddressBlock = addressBlock.querySelectorAll('input');
//
//     // function toggleAddressBlock() {
//     //     const selectedOption = document.querySelector('input[name="shipping_type_id"]:checked');
//     //     if (selectedOption) {
//     //         if (selectedOption.value === '4') {
//     //             addressBlock.style.display = 'none';
//     //             addressBlock.style.opacity = '0';
//     //             inputsInAddressBlock.forEach(input => input.classList.remove('need_validation'));
//     //         } else {
//     //             addressBlock.style.display = 'block';
//     //             addressBlock.style.opacity = '1';
//     //             inputsInAddressBlock.forEach(input => input.classList.add('need_validation'));
//     //         }
//     //     }
//     // }
//
//     function handleShippingChange() {
//         toggleAddressBlock();
//         updateShipping();
//     }
//
//     toggleAddressBlock();
//     deliveryOptions.forEach(option => option.addEventListener('change', handleShippingChange));
// }

// Инициализация всех обработчиков
document.addEventListener('DOMContentLoaded', function () {

    handleClearCart();
    handleShippingPrice();
    // handleToggleAddressBlock();
});

// Делегирование событий для обработки кликов на кнопки
document.addEventListener('click', function (event) {
    handleSendOrder(event);
    handleCartRemove(event);
});

function updateShipping() {

    console.log('Sending AJAX request for shipping type change');
    const selectedShippingType = document.querySelector('input[name="shipping_type_id"]:checked');
    if (selectedShippingType) {
        const fakeInput = document.createElement('input');
        fakeInput.setAttribute('type', 'hidden');
        fakeInput.value = selectedShippingType.value;
        obShopaholicCartUpdate.sendAjaxRequest(fakeInput, true); // Передаем true, чтобы указать, что это обновление способа доставки
    } else {
        console.log('No selected shipping type found');
    }
}


document.addEventListener('DOMContentLoaded', function () {
    const individualRadio = document.querySelector('input[name="property[type]"][value="2"]');
    const legalRadioCart = document.querySelector('input[name="property[type]"][value="3"]');
    const legalInfoCart = document.getElementById('legal-info');

    // Проверяем наличие элементов перед выполнением основной логики
    if (individualRadio && legalRadioCart && legalInfoCart) {
        // Изначально скрываем поля для юридического лица
        legalInfoCart.style.display = 'none';

        // Обработчик для переключения радиокнопок
        function toggleFields() {
            if (legalRadioCart.checked) {
                legalInfoCart.style.display = 'block';
                document.getElementById('organization-cart').setAttribute('required', 'required');
                document.getElementById('inn-cart').setAttribute('required', 'required');
            } else {
                legalInfoCart.style.display = 'none';
                document.getElementById('organization-cart').removeAttribute('required');
                document.getElementById('inn-cart').removeAttribute('required');
            }
        }

        // При смене значения запускаем обработчик
        individualRadio.addEventListener('change', toggleFields);
        legalRadioCart.addEventListener('change', toggleFields);

        // Применяем логику при загрузке страницы
        toggleFields();
    }
});
document.addEventListener('DOMContentLoaded', function () {
    const shippingTypeSelect = document.getElementById('shippingTypeSelect');
    const shippingTypeBlock = document.getElementById('shippingTypeBlock');

    // Проверяем наличие элементов на странице
    if (shippingTypeSelect && shippingTypeBlock) {
        // Функция для скрытия/показа блока в зависимости от выбранного типа доставки
        function toggleShippingBlock() {
            if (shippingTypeSelect.value === "1") { // Проверка на "Самовывоз"
                shippingTypeBlock.style.display = 'none';
            } else {
                shippingTypeBlock.style.display = 'block';
            }
        }

        // Добавляем обработчик события на изменение
        shippingTypeSelect.addEventListener('change', toggleShippingBlock);

        // Вызываем функцию при загрузке страницы для первоначальной проверки
        toggleShippingBlock();
    }
});
