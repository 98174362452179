/**
 * * Класс ShopaholicOrder управляет созданием заказа.
 *  *
 *  * Основные функции:
 *  *
 *  * - Подготовка объекта запроса с данными заказа из формы.
 *  * - Отправка AJAX-запроса для создания заказа.
 *  * - Установка коллбеков для обработки AJAX-запросов.
 */

export default class ShopaholicOrder {
  constructor() {

    this.sOrderFormClass = '_shopaholic-order-form';
    this.sOrderFieldClass = '_shopaholic-order-field';
    this.sGroupAttribute = 'data-group';
    this.sFieldAttribute = 'data-field';
    this.obAjaxRequestCallback = null;

    this.sComponentMethod = 'MakeOrder::onCreate';
    this.obOrder = {
      order: {
        property: {},
      },
      user: {},
      shipping_address: {},
      billing_address: {},
    };
  }

  /**
   * Prepare request object with order data
   * Send ajax request and create order
   */
  create() {
    this.prepareRequestObject();
    this.sendAjaxRequest();
  }

  /**
   * Prepare request object from order form
   */
  prepareRequestObject()
  {
    const obOrderForm = document.querySelector(`.${this.sOrderFormClass}`);
    if (!obOrderForm) {
      return;
    }

    const obFieldList = obOrderForm.querySelectorAll(`.${this.sOrderFieldClass}`);
    if (!obFieldList || obFieldList.length === 0) {
      return;
    }

    obFieldList.forEach((obFieldNode) => {
      this.addFieldValue(obFieldNode);
    });
  }

  /**
   * Process node and add field value in request object
   * @param {node} obFieldNode
   */
  addFieldValue(obFieldNode) {
    const sGroupOriginal = obFieldNode.getAttribute(this.sGroupAttribute);
    const sGroup = !!sGroupOriginal ? sGroupOriginal.replace(/-/g, '_').toLowerCase(): sGroupOriginal;
    let sFieldOriginal = obFieldNode.getAttribute(this.sFieldAttribute);
    let sField = !!sFieldOriginal ? sFieldOriginal.replace(/-/g, '_').toLowerCase() : sFieldOriginal;
    if (!sField) {
      sField = obFieldNode.name.replace(/-/g, '_').toLowerCase();
    }

    const {type: sType} = obFieldNode;

    if ((sType === 'radio' || sType === 'checkbox') && !obFieldNode.checked) {
      return;
    }

    const sValue = obFieldNode.value;
    if (sGroup) {
      sField = `${sGroup}.${sField}`;
    }

    this.addValueToObject(sField, sValue);
  }

  /**
   * Add field value in request object
   * @param {string} sField
   * @param {string} sValue
   */
  addValueToObject(sField, sValue) {
    // Разделяем имя поля на части (группы и подгруппы) по точкам
    const arFieldList = sField.split('.');
    // Начинаем с корневого объекта this.obOrder
    let obValueObject = this.obOrder;
    let sLastField = null;

    // Проходим по каждой части имени поля
    arFieldList.forEach((sFieldPart) => {
      // Если предыдущая часть поля была null, инициализируем её как пустой объект
      if (sLastField !== null && obValueObject[sLastField] === null) {
        obValueObject[sLastField] = {};
      }

      // Переходим на уровень ниже в объекте
      if (sLastField !== null) {
        obValueObject = obValueObject[sLastField];
      }

      sLastField = sFieldPart;

      // Инициализируем текущую часть поля как null, если она ещё не была определена
      if (obValueObject[sFieldPart] === undefined) {
        obValueObject[sFieldPart] = null;
      }
    });

    // Если конечное поле было null, присваиваем ему значение
    if (obValueObject[sLastField] === null) {
      obValueObject[sLastField] = sValue;
      // Если конечное поле уже содержит значение и оно не массив, создаем массив и добавляем текущее значение
    } else if (obValueObject[sLastField] !== null && !Array.isArray(obValueObject[sLastField])) {
      obValueObject[sLastField] = [obValueObject[sLastField], sValue];
      // Если конечное поле уже массив, просто добавляем новое значение в массив
    } else if (Array.isArray(obValueObject[sLastField])) {
      obValueObject[sLastField].push(sValue);
    }
  }

  /**
   * Send ajax request and create order
   */

  sendAjaxRequest() {
    let obRequestData = { data: this.obOrder };

    if (this.obAjaxRequestCallback !== null) {

      obRequestData = this.obAjaxRequestCallback(obRequestData);
    }

    oc.ajax(this.sComponentMethod, obRequestData)
        .done((response) => {
          if (response.status) {
            oc.flashMsg({
              message: 'Заказ успешно создан',
              type: 'success',
              interval: 2,
            });
          } else {
            oc.flashMsg({
              message: response.message || 'Создание заказа',
              type: 'success',
              interval: 2,
            });
          }
        })
        .fail((response) => {
          oc.flashMsg({
            message: 'Произошла ошибка при создании заказа. Попробуйте еще раз.',
            type: 'error',
            interval: 2,
          });
        });
  }

  /**
   * Set ajax request callback
   *
   * @param {function} obCallback
   * @returns {ShopaholicOrder}
   */
  setAjaxRequestCallback(obCallback) {
    this.obAjaxRequestCallback = obCallback;

    return this;
  }
}
